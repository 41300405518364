import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import {  ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { SongComponent } from './pages/song/song.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';
import { AgregarCancionComponent } from './pages/agregar-cancion/agregar-cancion.component';
import { LoadingSpinnersModule } from 'ngx-loading-spinners';
import { SharedModule } from './shared/shared-module.module';
import { CommonModule } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';
import * as Hammer  from "hammerjs";
import { HammerGestureConfig,HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { LoginComponent } from './pages/login/login.component';
import { TeoriamusicalComponent } from './teoriamusical/teoriamusical.component';

 // import bn-ng-idle service


@NgModule({
  imports: [
    BrowserAnimationsModule,
    // CommonModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
   
        // LoadingSpinnersModule

    // BrowserModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
   
    // LoginComponent
   
  
  
    
  ],
  providers: [BnNgIdleService],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
