import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router , } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessService } from '../services/accesos/access.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  
  constructor(private auth:AccessService, private router: Router) {
    
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;

   


    if (!localStorage.getItem('usuario')){

        
      let user = this.auth.isAuth();
      console.log('user', user)
      // console.log('his.router.url', this.router.url)

      return this.router.navigate(['/login']).then(() => false);
    }
    
    
    
  

    // if (user.tipo == '2' && (this.router.url == '/')){
    
    //   console.log('entro a segundo guard');
  
    //   this.router.navigate(['/tables']);
    //   return false
    // }
    
    return true;
  }
}
