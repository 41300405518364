import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map,delay , timeout} from "rxjs/operators";
import { Acceso } from '../../models/Acceso';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  private URL ="https://chords-ebd42.firebaseio.com";

  acceso : any;
  AccesosGeneral : any[] = [];

  constructor(private http:HttpClient) {
   }


    obtenerIDproducto(){
      return this.http.get(`${this.URL}/producto.json`)
      .pipe( timeout(3000),
        map( this.crearArregloIDPROD),
        // // hace demorar la funciona por la cantida de segundos que indicas
        // delay(1500)
      );
    }


    private crearArregloIDPROD(accesObj: object){

      let accesos:any[] = [];
  
      if (accesObj === null){
        return [];
      }
      Object.keys(accesObj).forEach(key =>{
  
        let acceso: any = accesObj[key];
        acceso.auto = key;
        // acceso.eye = false;
        accesos.push(acceso);
      })
  
  
     
  
    return accesos;
  }

   obtenerCodigo(){

    return this.http.get(`${this.URL}/accesos.json`)
          .pipe( timeout(3000),
            map( this.crearArreglo),
            // // hace demorar la funciona por la cantida de segundos que indicas
            // delay(1500)
          );
   }

   isAuth(){
    return localStorage.getItem('usuario');
   }


   private crearArreglo(accesObj: object){

    let accesos:any[] = [];

    if (accesObj === null){
      return [];
    }
    Object.keys(accesObj).forEach(key =>{

      let acceso: any = accesObj[key];
      acceso.auto = key;
      acceso.eye = false;
      accesos.push(acceso);
    })


   

  return accesos;
}

actualizarProducto(access:Acceso){

  let accessTemp = {
    ...access
  } 

  // delete accessTemp.id;
  // accessTemp.activo = !access.activo; 
  //  console.log('heroeTemp: ', accessTemp);

    return this.http.put(`${this.URL}/producto/${access.auto}.json`,accessTemp);

 }

actualizarAcceso(access:Acceso){

  let accessTemp = {
    ...access
  } 

  // delete accessTemp.id;
  accessTemp.activo = !access.activo; 
  //  console.log('heroeTemp: ', accessTemp);

    return this.http.put(`${this.URL}/accesos/${access.auto}.json`,accessTemp);

 }


borrarHeror(auto:string){

  return this.http.delete(`${this.URL}/accesos/${auto}.json`);
 }

 crearHeroe( heroe:Acceso){

  return this.http.post(`${this.URL}/accesos.json`,heroe)
      .pipe(
        map( (resp:any) => {
          // heroe.id = resp.name;
          return heroe;
        })
      )

 }

 actualizarHeroe(heroe:Acceso){

  let heroeTemp = {
    ...heroe
  } 

  delete heroeTemp.id;

    return this.http.put(`${this.URL}/accesos/${heroe.id}.json`,heroeTemp);

 }
}
