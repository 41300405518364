import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map,delay,timeout } from "rxjs/operators";
import { Cancion } from '../../models/Cancion';

@Injectable({
  providedIn: 'root'
})
export class SongsService {


  private URL ="https://chords-ebd42.firebaseio.com";

  numero : string = '123';
  validado:boolean = false;
  cancionesGeneral : any = [];
  cancionesAcorde : any = [];
  cancion : Cancion;

  usuario : any = '';


  constructor(private http:HttpClient) {
    // console.log('Servicio listo para usarse');

    
  }

  crearCancion( cancion:Cancion){

    return this.http.post(`${this.URL}/canciones.json`,cancion)
        .pipe(
          map( (resp:any) => {
            // heroe.id = resp.name;
            return cancion;
          })
        )

   }


   borrarCancion(auto:string){

    return this.http.delete(`${this.URL}/canciones/${auto}.json`);
   }

   actualizarCancion(cancion:Cancion){

    let cancionTemp = {
      ...cancion
    }

    // delete accessTemp.id;
    // cancionTemp.activo = !access.activo;
    //  console.log('heroeTemp: ', cancionTemp);

      return this.http.put(`${this.URL}/canciones/${cancion.auto}.json`,cancionTemp);

   }



  obtenerCanciones(){

    return this.http.get(`${this.URL}/canciones.json`)
          .pipe(  timeout(3000),
            map( this.crearArreglo)
            // // hace demorar la funciona por la cantida de segundos que indicas
            // delay(1500)
          );
   }

  private crearArreglo(heroesObj: object){

      let songs:any[] = [];

      if (heroesObj === null){
        return [];
      }
      Object.keys(heroesObj).forEach(key =>{

        let song: any = heroesObj[key];
        song.auto = key;
        // song.urls = '';

        songs.push(song);
      })




    return songs;
  }



  buscarCancion(termino: string,iglesia? : string): any[] {

    let songs: any[] = [];

    let termino1 = termino.toLocaleLowerCase();

    if(iglesia == "TODO"){

      for (let item of this.cancionesGeneral) {

        let cancion = item.titulo.toLocaleLowerCase();
  
        if (cancion.indexOf(termino1) >= 0) {
  
          songs.push(item);
        }
      }
  
      return songs;

    }
    // else if (){

    // }
    else {
      for (let item of this.cancionesGeneral.filter(taber => taber.taber == iglesia )) {

        let cancion = item.titulo.toLocaleLowerCase();
  
        if (cancion.indexOf(termino1) >= 0) {
  
          songs.push(item);
        }
      }
  
      return songs;
    }
  
  }

  buscarCancionDashboard(termino: string,iglesia? : string): any[] {

    let songs: any[] = [];

    let termino1 = termino.toLocaleLowerCase();
    
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    // console.log('this.usuario', this.usuario)

    if(this.usuario.taber == null){

      for (let item of this.cancionesGeneral) {

        let cancion = item.titulo.toLocaleLowerCase();
  
        if (cancion.indexOf(termino1) >= 0) {
  
          songs.push(item);
        }
      }
  
      return songs;
    }
    if (this.usuario.taber)
    {
      for (let item of this.cancionesGeneral.filter(taber => taber.taber == this.usuario.taber )) {

        let cancion = item.titulo.toLocaleLowerCase();
  
        if (cancion.indexOf(termino1) >= 0) {
  
          songs.push(item);
        }
      }
  
      return songs;
    }
  
  }


  // buscarCancionID(termino: string): any[] {

  //   let songs: any[] = [];

    

  //   for (let item of this.cancionesGeneral) {

  //     let cancion = item.id;

  //     if (cancion == termino) {

  //       songs.push(item);
  //     }
  //   }

  //   return songs;
  // }

  buscarCancionAcorde(acorde: string , iglesia): any[] {
    
    // let user = JSON.parse(localStorage.getItem('usuario'));
    let songs: any[] = [];

    //  let termino1 = termino.toLocaleLowerCase();
    if(acorde == "TODO" && iglesia == "TODO"){


       this.cancionesAcorde = this.cancionesGeneral;
      return this.cancionesGeneral;
    }

    if(acorde != "TODO" && iglesia == "TODO"){

      for (let item of this.cancionesGeneral) {

        let cancion = item.acorde;
        
  
        if (cancion == acorde ) {
  
          songs.push(item);
        }
      }
      this.cancionesAcorde = songs;

      return songs;
    }


    if(acorde == "TODO" && iglesia != "TODO"){

      for (let item of this.cancionesGeneral) {

        let cancion = item.acorde;

        let tabernaculo = item.taber;

  
        if (tabernaculo == iglesia ) {
  
          songs.push(item);
        }
      }
      this.cancionesAcorde = songs;

      return songs;
    }

    if(acorde != 'TODO' && iglesia != 'TODO'){


      for (let item of this.cancionesGeneral) {
  
        let cancion = item.acorde;
        let tabernaculo = item.taber;
  
        if (cancion == acorde && tabernaculo == iglesia) {
  
          songs.push(item);
        }
      }
      this.cancionesAcorde = songs.filter(taber => taber.taber == iglesia);
      // this.cancionesGeneral = songs.filter(taber => taber.taber == iglesia);

      return songs;
    }

    // if(acorde == 'TODO' && iglesia != 'TODO'){


    //   for (let item of this.cancionesGeneral) {
  
    //     let cancion = item.acorde;
    //     let tabernaculo = item.taber;
  
    //     if (cancion == acorde && tabernaculo == iglesia) {
  
    //       songs.push(item);
    //     }
    //   }
    //   this.cancionesAcorde = songs.filter(taber => taber.taber == iglesia);
    //   this.cancionesGeneral = songs.filter(taber => taber.taber == iglesia);
    //   console.log('this.cancionesAcorde', this.cancionesAcorde)
    //   return songs;
    // }

  }
}
