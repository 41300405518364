import { Component } from '@angular/core';
import { SongsService } from './services/songs/songs.service';
import { Router } from '@angular/router';
import { DataService } from './services/data/data.service';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'argon-dashboard-angular';
  loading : boolean = false;
  canciones : any[] = [];

  constructor(private songsService:SongsService, private router :Router, private dataService:DataService , private bnIdle: BnNgIdleService) {
    // this.cargarCanciones();

   }

   ngOnInit(){
    // this.cargarCanciones();
    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        Swal.fire({
          title: "Session Cerrada",
          text: "Sessión cerrada por inactividad",
          // type: "warning",
          allowOutsideClick: false
        }).then(resp => {
          if (resp.value == true) {
            this.router.navigate(["/login"]);
          }
        });
        console.log('session expired');
      };
  });
  }

  // cargarCanciones(){
  //   this.loading  =true;
  //   this.songsService.obtenerCanciones()
  //       .subscribe(resp =>{
  //         this.canciones = resp as any ;
  //         this.songsService.cancionesGeneral = this.canciones;
         
  //         console.log( this.canciones);
  //       });

  // }
}
