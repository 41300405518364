import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  Titulo : string = "";
  TodasCanciones : any[] = [];
  MisCanciones : any[] = [];
  userKey : number= 0;
  typeUser : number = 2;
  cancionQueVaAActualizar : any;
  IDPROD : number ;
  AVISO: any= [];
  estirado : boolean = false;
  constructor() { }
}
